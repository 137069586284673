import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";

function HeadQuarter() {
  const iconsStyle = "text-blue-600  text-2xl m-2 ml-0 font-bold";
  return (
    <div className="px-8 py-3">
      {/* <div className="lg:pl-6 lg:pr-8 ">
        <h2 className="heading">Bariflo Cybernetics - HQ</h2>
        <p>207 Second Floor Kiit TBI Campus 11,<br /> Patia, Bhubaneswar, Odisha</p>
      </div> */}

      <div className=" lg:px-6  py-2">
      <h2 className="heading">Registered Address: </h2>
        <h2 className="heading">20 Micklefield Ave</h2>
        <p>
        Whitby,Toronto, <br></br>Ontario L1P 0C3
        </p>
      </div>

      <div className="  lg:px-6  py-2">
        <h2 className="heading">Get in touch</h2>
        <p>
          Mobile: +1(647)296-9976 <br /> 
          </p>
          <h2 className="heading pt-2">Corporate Address: </h2>
          <p>Venn Innovation, <br />770 St George Blvd, Moncton,<br /> NB E1E 2C6, Canada</p>
 
          <p>
          Mobile: +1 506-866-6695 <br /> 
          Email: michelle@bariflolabs.com
        </p>
      </div>

      <div className="w-[15rem] lg:px-6  py-2  flex flex-col">
        <h2 className="heading">Follow Us On</h2>
        <div className="flex">
          <FaFacebookF className={iconsStyle} />
          <FaLinkedinIn className={iconsStyle} />
          <FaXTwitter className={iconsStyle} />
          <FaInstagram className={iconsStyle} />
        </div>
      </div>
    </div>
  );
}

export default HeadQuarter;
